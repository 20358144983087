<template>
  <cursoForm
    btnSubmit="Crear Curso"
    :curso="data"
    @processForm="agregar"
  />
</template>

<script>

import { mapActions } from 'vuex'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import cursoForm from './CursosForm.vue'

export default {
  components: {
    cursoForm,
  },
  data() {
    return {
      data: {
        id_calendario: 1,
        id_codigo_grado: 1,
        letra: 'A',
        total_alumnos: 40,
        combinado: false,
        tipo_jornada: 1,
        id_especialidad: 92004,
        alternativa: false,
        infraestructura: false,
      },
    }
  },
  methods: {
    ...mapActions({ addCurso: 'cursos/addCurso' }),
    agregar(curso) {
      this.addCurso(curso).then(() => {
        const errorCursos = store.state.cursos
        const errorMessage = errorCursos.errorMessage.errors
        if (!errorCursos.error) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Curso creado 👍',
              icon: 'CheckIcon',
              text: `El curso "${curso.nombre}" fue guardado con éxito!`,
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
            timeout: 3000,
          })
          this.$router.replace({
            name: 'cursos',
          })
        } else if (errorMessage.nombre) {
          this.$swal({
            title: 'Error!',
            text: `${errorMessage.nombre[0]}!`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$swal({
            title: 'Error!',
            text: 'Ingreso de datos fraudulento!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        }
      })
    },
  },
}
</script>
